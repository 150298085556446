import React, { useState, useEffect } from "react";
import { pageActions as actions } from "../../../store/page";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import ContentBox from "../../../components/ContentBox";
import { Button, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import * as Yup from "yup";
import { Formik, Form as FormFormik, Field, FormikHelpers } from "formik";
import FocusError from "../../../helpers/FocusError";
import Styles from "./index.module.css";
import InputField from "../../../components/Form/InputField";
import CoProducerServices, { CoProducerDto, ProducerBankAccountDto } from "../../../services/coProducerServices";
import { BackButton } from "../../../components/BackButton";
import SelectField from "../../../components/Form/SelectField";
import OpportunityService, { ModelEmailAieDto, OpportunityDto } from "../../../services/opportunityServices";
import InvestorServices, { IbanTradeAccount } from "../../../services/investorServices";
import { BasicModal } from "../../../components/BasicModal/Index";
import SelectWWO from "../../../components/Form/SelectWWO";
import { AnnualInvestmentCharterDto } from "../../../services/annualInvestmentChartersServices";
import DataTable from "../../../components/Table";

type Props = {
    updatePageHeaderTitle: (title: string) => void;
};

const AssigmentEmailsAie = (props: Props) =>
{
    const { updatePageHeaderTitle } = props;
    const { productId } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const opportunities: OpportunityDto[] = state.opportunities;
    const productype: string = state.productype;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modelEmail, setModelEmail] = useState<ModelEmailAieDto>();
    const [bankAccountProducer, setBankAccountProducer] = React.useState<ProducerBankAccountDto[]>([]);
    const [bankAccountTrade, setBankAccountTrade] = React.useState<IbanTradeAccount[]>([]);
    const [error, setError] = React.useState("");
    const [errorModal, setErrorModal] = React.useState<boolean>(false);
    const [emailModal, setEmailModal] = React.useState<boolean>(false);
    const charters: AnnualInvestmentCharterDto[] = state.charters;
    const inversionInfo: OpportunityDto[] = state.inversionInfo;
    const capitalToRise: string | undefined = state?.capitalToRise;

    useEffect(() =>
    {
        updatePageHeaderTitle("Crear asignación. Paso 5: Envio de Correos al Inversor");
    }, [updatePageHeaderTitle]);

    useEffect(() =>
    {

        getIbanTradeData();
        getBankAccountProducer(opportunities[0].productId);

    }, [updatePageHeaderTitle]);

    const getIbanTradeData = () =>
    {
        setIsLoading(true);

        InvestorServices.getIbansTrade()
            .then(response =>
            {
                setBankAccountTrade(response);
            })
            .finally(() =>
            {
                setIsLoading(false);
            }).catch(() =>
            {
                setErrorModal(false);
                setError("Ha ocurrido un error al intentar obtener las cuentas bancarias");
            });
    }

    const getBankAccountProducer = (productId: number) =>
    {
        setIsLoading(true);

        CoProducerServices.getBankAccountProducer(productId)
            .then(response =>
            {
                setBankAccountProducer(response);
            })
            .finally(() =>
            {
                setIsLoading(false);
            }).catch(() =>
            {
                setErrorModal(false);
                setError("Ha ocurrido un error al intentar obtener las cuentas bancarias");
            });
    }


    const initialValues: ModelEmailAieDto[] = charters.map((charter: AnnualInvestmentCharterDto) =>
    {
        var opportunity = opportunities.find(o => o.investorId == charter.investorId);
        var inversion = inversionInfo.find(o => o.investorId == charter.investorId);

        var data = {
            investorId: Number(charter.investor.id) ?? 0,
            opportunityId: opportunity != undefined ? Number(opportunity.id) : 0,
            investorName: charter.investor.name ?? "",
            productId: Number(productId) ?? 0,
            inversion: inversion?.commitment,
            ibanAportation: modelEmail?.ibanAportation ?? "",
            amountAportation: modelEmail?.amountAportation ?? 0,
            ibanTrade: modelEmail?.ibanTrade ?? 0,
            amountTrade: modelEmail?.amountTrade ?? 0
        };

        return data;
    });



    const submitHandler = (values: ModelEmailAieDto[], actions: FormikHelpers<ModelEmailAieDto[]>) =>
    {
        setIsLoading(true);

        Promise.all(values.map((email: ModelEmailAieDto) => OpportunityService.postOpportunityEmailAie(email))).then(response =>
        {
            if (response.length == response.filter(e => e == true).length)
            {
                setEmailModal(true);
            }
            else
            {
                setErrorModal(false);
                setError("Ha ocurrido un error al intentar enviar los emails");
            }

        })
            .finally(() =>
            {
                setIsLoading(false);
            }).catch(() =>
            {
                setErrorModal(false);
                setError("Ha ocurrido un error al intentar enviar los emails");
            });
    };

    const validationSchema =
        Yup.array().of(
            Yup.object().shape({
                investorId: Yup.number().required("Campo obligatorio"),
                inversion: Yup.number().required("Campo obligatorio"),
                ibanAportation: Yup.string().required("Campo obligatorio"),
                amountAportation: Yup.number()
                    .required("Campo obligatorio")
                    .test({
                        name: "aportation-match",
                        test(value, ctx)
                        {
                            const { amountTrade, inversion } = this.parent;
                            var data = value + amountTrade === inversion;

                            if (data == false)
                            {
                                return ctx.createError({ message: "Cantidad Aportación + Cantidad Compra/Venta debe ser igual a la Inversión" });
                            }
                            return data;
                        }
                    }),
                ibanTrade: Yup.string().required("Campo obligatorio"),
                amountTrade: Yup.number()
                    .required("Campo obligatorio")
                    .test({
                        name: "trade-match",
                        test(value, ctx)
                        {
                            const { amountAportation, inversion } = this.parent;
                            var data = value + amountAportation === inversion;

                            if (data == false)
                            {
                                return ctx.createError({ message: "Cantidad Aportación + Cantidad Compra/Venta debe ser igual a la Inversión" });
                            }
                            return data;
                        }
                    }),
            }));

    return (
        <>
            {isLoading && <Loading type="component"></Loading>}
            {!isLoading && (<ContentBox>
                <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema} enableReinitialize>
                    {({ values, isSubmitting, setFieldValue, touched, errors }) => (
                        <FormFormik>
                            {/* DS Help Tools */}
                            {/* {JSON.stringify(values, null, 2)} */}
                            {/* {JSON.stringify(errors)} */}
                            <Row style={{ marginBottom: "20px" }}>
                                <Col className="textRight">
                                    <Button
                                        className="mr-10"
                                        disabled={isLoading}
                                        onClick={() =>
                                        {
                                            navigate(`/assignments/${productId}/assignInvestment`, {
                                                state: { opportunities, productype, charters, capitalToRise, inversionInfo }
                                            });
                                        }}
                                    >
                                        &#60; Anterior
                                    </Button>
                                    <Button type="submit" disabled={isLoading}>
                                        Siguiente &#62;
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-20">
                                    <DataTable
                                        id="investorTable"
                                        size="lg"
                                        isLoading={false}
                                        totalRows={charters?.length}
                                        selectedRowsPerPage={25}
                                        head={
                                            <tr key="headerkey">
                                                <th>Inversor</th>
                                                <th>Inversión</th>
                                                <th>Iban Aportación</th>
                                                <th>Cantidad Aportación</th>
                                                <th>Iban Compra/Venta</th>
                                                <th>Cantidad Compra/Venta</th>
                                            </tr>
                                        }
                                        body={initialValues.map((e: ModelEmailAieDto, index: number) => (
                                            <tr key={index}>
                                                <td style={{ width: "280px" }}>
                                                    <Field
                                                        name={`${index}.investorName`}
                                                        type="text"
                                                        component={InputField}
                                                        placeholder="Inversor"
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td style={{ width: "160px" }}>
                                                    <Field
                                                        name={`${index}.inversion`}
                                                        type="number"
                                                        component={InputField}
                                                        isInvalid={errors[index]?.inversion && touched[index]?.inversion}
                                                        placeholder="Inversión"
                                                        disabled={true}
                                                        suffix={" €"}
                                                    />
                                                </td>
                                                <td style={{ width: "280px" }}>
                                                    <Field
                                                        name={`${index}.ibanAportation`}
                                                        component={SelectField}
                                                        isInvalid={errors[index]?.ibanAportation && touched[index]?.ibanAportation}
                                                    >
                                                        {bankAccountProducer &&
                                                            bankAccountProducer.map((bankAccount, index) => (
                                                                <option key={bankAccount.iban} value={bankAccount.iban}>
                                                                    {bankAccount.iban}
                                                                </option>
                                                            ))}
                                                    </Field>
                                                </td>
                                                <td style={{ width: "160px" }}>
                                                    <Field
                                                        name={`${index}.amountAportation`}
                                                        type="number"
                                                        component={InputField}
                                                        isInvalid={errors[index]?.amountAportation && touched[index]?.amountAportation}
                                                        placeholder="Cantidad Aportación"
                                                        invalidText={errors[index]?.amountAportation}
                                                        suffix={" €"}
                                                    />
                                                </td>
                                                <td style={{ width: "280px" }}>
                                                    <Field
                                                        name={`${index}.ibanTrade`}
                                                        component={SelectField}
                                                        placeholder="IBAN Compraventa"
                                                        isInvalid={errors[index]?.ibanTrade && touched[index]?.ibanTrade}
                                                    >
                                                        {bankAccountTrade &&
                                                            bankAccountTrade.map((bankAccount, index) => (
                                                                <option key={bankAccount.id} value={bankAccount.id}>
                                                                    {bankAccount.destinatary}
                                                                </option>
                                                            ))}
                                                    </Field>
                                                </td>
                                                <td style={{ width: "160px" }}>
                                                    <Field
                                                        name={`${index}.amountTrade`}
                                                        type="number"
                                                        component={InputField}
                                                        isInvalid={errors[index]?.amountTrade && touched[index]?.amountTrade}
                                                        placeholder="Cantidad Compra/Venta"
                                                        invalidText={errors[index]?.amountTrade}
                                                        suffix={" €"}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    />
                                </Col>
                            </Row>
                        </FormFormik>
                    )}
                </Formik>
                <BasicModal
                    title="Oportunidades creadas"
                    body={<>Las oportunidades han sido creadas. Pulse el botón aceptar para continuar.</>}
                    btnSubmitText="Aceptar"
                    showModal={emailModal}
                    onSubmit={() =>
                    {
                        setEmailModal(false);
                        navigate(`/assignments/${productId}/selectFiles`, {
                            state: { opportunities, productype, charters, capitalToRise, inversionInfo }
                        });
                    }}
                    onClose={() =>
                    {
                        setEmailModal(false);
                        navigate(`/assignments/${productId}/selectFiles`, {
                            state: { opportunities, productype, charters, capitalToRise, inversionInfo }
                        });
                    }}
                ></BasicModal>
                <BasicModal
                    title="Error"
                    body={<>{error}</>}
                    btnSubmitText="Aceptar"
                    showModal={errorModal}
                    onSubmit={() =>
                    {
                        setErrorModal(false);
                    }}
                    onClose={() =>
                    {
                        setErrorModal(false);
                    }}
                ></BasicModal>
            </ContentBox>)}
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updatePageHeaderTitle: (title: string) => dispatch(actions.updatePageHeaderTitle(title))
});

export default connect(null, mapDispatchToProps)(AssigmentEmailsAie);
