import TextField from "@mui/material/TextField";
import React from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
    title?: string;
    body?: string | JSX.Element | JSX.Element[];
    btnSubmitText?: string;
    btnSubmitDisabled?: boolean;
    onSubmit?: any;
    onClose?: any;
    size?: "sm" | "lg" | "xl";
    showModal: boolean;
    sendPetition: any;
    showValidateModal: (b: boolean) => any;
    correctResponse: boolean;
};

export const ModalWithInputsVersionEmail = (props: Props) =>
{
    const [show, setShow] = React.useState(false);
    const [bankAccountFirst, setBankAccountFirst] = React.useState("");
    const [bankAccountSecond, setBankAccountSecond] = React.useState("");
    const [error, setError] = React.useState("");
    const patternIBAN =
        "(([E+S]|[e+s]){2})\t*(\\d{2})\\s*\t*(\\d{4})\\s*\t*(\\d{4})\\s*\t*(\\d{2})\\s*\t*(\\d{10})";
    const [destinatary, setDestinatary] = React.useState<string>("");
    const [concept, setConcept] = React.useState<string>("");

    const {
        body,
        title,
        showModal,
        showValidateModal,
        correctResponse,
        size
    } = props;

    React.useEffect(() =>
    {
        setShow(showModal);
    }, [showModal]);

    const handleClose = () =>
    {
        showValidateModal(false);
        if (props.onClose !== undefined)
        {
            props.onClose();
        }

        resetForm();
    };

    const resetForm = () =>
    {
        setBankAccountFirst("");
        setBankAccountSecond("");
        setError("");
    };

    React.useEffect(() =>
    {
        setShow(showModal);
    }, [showModal]);

    React.useEffect(() =>
    {
        if (correctResponse == true)
        {
            handleClose();
        }
    }, [correctResponse]);

    const onSubmit = async () =>
    {
        setError("");

        let newReg = new RegExp(patternIBAN);

        if (
            bankAccountFirst === "" || bankAccountFirst === undefined ||
            bankAccountSecond === "" || bankAccountSecond === undefined ||
            destinatary === undefined || destinatary === "" ||
            concept === undefined || concept === ""
        )
        {
            return setError("Los campos deben estar rellenados para poder enviarse");
        } else if (bankAccountFirst != bankAccountSecond)
        {
            return setError(
                "Debes introducir el mismo número de cuenta en los dos campos"
            );
        } else if (
            !bankAccountFirst.match(newReg) ||
            !bankAccountFirst.match(newReg)
        )
        {
            return setError("El IBAN no tiene un formato válido. Un ejemplo es: ES1234567891234567891234. El IBAN no puede contener ni espacios, ni guiones, ni carácteres especiales");
        } else
        {
            if (props.sendPetition !== undefined)
            {
                setError("");
                props.sendPetition(bankAccountFirst, destinatary, concept);
            }
        }
    };

    const handleChangeFirstAccount = (e: any) =>
    {
        setBankAccountFirst(e.target.value);

        if (e.target.value !== "" && e.target.value !== undefined)
        {
            setError("");
        }
    };

    const handleChangeSecondAccount = (e: any) =>
    {
        setBankAccountSecond(e.target.value);

        if (e.target.value !== "" && e.target.value !== undefined)
        {
            setError("");
        }
    };


    return (
        <>
            <Modal show={show} onHide={handleClose} size={size}>
                <Modal.Header closeButton>
                    <b>{title}</b>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <TextField
                            value={destinatary}
                            required
                            label={"Destinatario Cuenta bancaria"}
                            placeholder="Destinatario Cuenta Bancaria"
                            onChange={(e: any) => setDestinatary(e.target.value)}
                            style={{ width: "380px" }}
                        />
                        <TextField
                            value={concept}
                            required
                            label={"Concepto Cuenta bancaria"}
                            placeholder="Concepto Cuenta Bancaria"
                            onChange={(e: any) => setConcept(e.target.value)}
                            style={{ width: "380px" }}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                        <TextField
                            value={bankAccountFirst}
                            required
                            label={"IBAN cuenta bancaria"}
                            placeholder="Cuenta Bancaria"
                            onChange={handleChangeFirstAccount}
                            style={{ width: "380px" }}
                        />

                        <TextField
                            value={bankAccountSecond}
                            required
                            label={"Repita IBAN"}
                            placeholder="Cuenta Bancaria"
                            onChange={handleChangeSecondAccount}
                            style={{ width: "380px" }}
                        />
                    </div>
                    {(error !== undefined || error !== "") && (
                        <div style={{ display: "flex", justifyContent: "center" }}> <p style={{ color: "red" }}>{error}</p></div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>

                    <Button
                        onClick={() =>
                        {
                            onSubmit();
                        }}
                    >
                        Crear
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
};
