import React from "react";
import { useEffect, useState } from "react";
import { pageActions as actions } from "../../../store/page";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import ContentBox from "../../../components/ContentBox";
import Loading from "../../../components/Loading";
import { BackButton } from "../../../components/BackButton";
import AnnualInvestmentCharterServices, { AnnualInvestmentCharterDto } from "../../../services/annualInvestmentChartersServices";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ContractDocsVersionDto } from "../../../services/contractDocsServices";
import { InvestorDto } from "../../../services/investorServices";
import { Field, FormikHelpers, useFormik, FieldArray, FormikProvider } from "formik";
import Styles from "./index.module.css";
import InputField from "../../../components/Form/InputField";
import ContractDocServices from "../../../services/contractDocsServices";
import { ContractTypes } from "../../../services/annualInvestmentChartersServices";
import { currencyFormatter } from "../../../helpers/numberFormatter";
import InvestorServices, {
    maritalStatuses,
    matrimonialRegimes,
    representativePositions
} from "../../../services/investorServices";
import SelectField from "../../../components/Form/SelectField";
import { investorTypePerson } from "../../../types/InvestorTypes";
import CountryPrefixes from "../../../helpers/CountryPrefixes";
import { array, boolean, date, number, object, setLocale, string } from "yup";
import Input from "../../../components/Form/Input";
import DataTable from "../../../components/Table";
import { BasicModalWithoutCancel } from "../../../components/BasicModalWithoutCancel/Index";

type Props = {
    updatePageHeaderTitle: (title: string) => void;
    investorData?: InvestorDto;
    document: any;
};

const LetterCreationStepThree = (props: Props) =>
{
    const { updatePageHeaderTitle, investorData, document } = props;
    let navigate = useNavigate();

    const [investor, setInvestor] = useState<InvestorDto>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contractDocVersion, setContractDocVersion] = useState<ContractDocsVersionDto>();
    const [letterCreatedModal, setLetterCreatedModal] = useState<boolean>(false);


    const AnnualAIEInvestmentCharterContractSlug = "CAI-AIE";
    const AnnualFinancingContractInvestmentCharterContractSlug = "CAI-CF";

    React.useEffect(() =>
    {
        updatePageHeaderTitle("Crear Carta. Paso 3. Completar información de la carta ");
    }, [updatePageHeaderTitle]);

    React.useEffect(() =>
    {
        if (investorData != undefined)
        {
            setInvestor(investorData)
        }
    }, []);


    const initialValues: AnnualInvestmentCharterDto = {
        amount: 0,
        contractType: 0,
        signingAtNotary: undefined,
        alternativeNotary: undefined,
        signAPowerOfAttorneyToTaxman: undefined,
        spanishNatitonality: undefined,
        incomeOfEconomicActivities: undefined,
        investor: {
            id: investor?.id ?? undefined,
            name: investor?.name ?? undefined,
            typePersonId: investor?.typePersonId ?? 1,
            address: investor?.address ?? undefined,
            cif: investor?.cif ?? undefined,
            email: investor?.email ?? undefined,
            countryPrefix: investor?.countryPrefix ?? "+34",
            phone: investor?.phone ?? undefined,
            city: investor?.city ?? undefined,
            postalCode: investor?.postalCode ?? undefined,
            naturalPersonInvestor: {
                id: investor?.naturalPersonInvestor?.id,
                profession: investor?.naturalPersonInvestor?.profession,
                maritalStatus: investor?.naturalPersonInvestor?.maritalStatus,
                matrimonialRegime: investor?.naturalPersonInvestor?.matrimonialRegime,
                spouseFullname: investor?.naturalPersonInvestor?.spouseFullname,
                spouseDocument: investor?.naturalPersonInvestor?.spouseDocument,
                spouseAddress: investor?.naturalPersonInvestor?.spouseAddress,
                spouseProfession: investor?.naturalPersonInvestor?.spouseProfession
            },
            legalPersonInvestor: {
                id: investor?.legalPersonInvestor?.id,
                cnae: investor?.legalPersonInvestor?.cnae,
                fiscalYearEndDate: investor?.legalPersonInvestor?.fiscalYearEndDate,
                representativePosition: investor?.legalPersonInvestor?.representativePosition,
                annualBilling: investor?.legalPersonInvestor?.annualBilling ?? ("" as unknown as number),
                representatives: investor?.legalPersonInvestor?.representatives ?? [
                    {
                        fullname: "",
                        document: "",
                        countryPrefix: "+34",
                        phone: "",
                        email: ""
                    }
                ]
            }
        },
        contractDocsVersionId: 0,
        investorId: investor != undefined && investor.id != undefined ? investor.id : 0
    };

    setLocale({
        mixed: {
            required: `Campo obligatorio`
        }
    });

    const naturalPersonSchema = object().shape({
        profession: string().max(100).required(),
        maritalStatus: number().required(),
        matrimonialRegime: object()
            .nullable()
            .when("maritalStatus", {
                is: (maritalStatus: number) => maritalStatus === 1,
                then: schema => number().required()
            }),
        spouseFullname: string()
            .nullable()
            .when(["maritalStatus", "matrimonialRegime"], {
                is: (maritalStatus: number, matrimonialRegime: number) => maritalStatus === 1 && matrimonialRegime === 0,
                then: schema => string().max(500).required()
            }),
        spouseDocument: string()
            .nullable()
            .when(["maritalStatus", "matrimonialRegime"], {
                is: (maritalStatus: number, matrimonialRegime: number) => maritalStatus === 1 && matrimonialRegime === 0,
                then: schema => string().max(20).required()
            }),
        spouseAddress: string()
            .nullable()
            .when(["maritalStatus", "matrimonialRegime"], {
                is: (maritalStatus: number, matrimonialRegime: number) => maritalStatus === 1 && matrimonialRegime === 0,
                then: schema => string().max(200).required()
            }),
        spouseProfession: string()
            .nullable()
            .when(["maritalStatus", "matrimonialRegime"], {
                is: (maritalStatus: number, matrimonialRegime: number) => maritalStatus === 1 && matrimonialRegime === 0,
                then: schema => string().max(100).required()
            })
    });

    const legalPersonSchema = object().shape({
        cnae: string().max(4, "4 caracteres máximo").required(),
        fiscalYearEndDate: date().required(),
        representativePosition: number().required(),
        annualBilling: number().required(),
        representatives: array().of(
            object({
                fullname: string().max(500).required(),
                document: string().max(20).required(),
                countryPrefix: string().max(6).required(),
                phone: string().max(20).required(),
                email: string().email().max(100).required()
            })
        )
    });

    const investorSchema = object().shape({
        typePersonId: number().required(),
        name: string().max(500).required(),
        cif: string().max(20),
        address: string().max(500),
        email: string().email().max(100).required(),
        countryPrefix: string().max(6).required(),
        phone: string().min(9).max(12).required(),
        city: string().max(100),
        postalCode: number().positive(),
        taxRate: number(),
        naturalPersonInvestor: object().when("typePersonId", {
            is: (typePersonId: number) => typePersonId === 1,
            then: schema => naturalPersonSchema
        }),
        legalPersonInvestor: object().when("typePersonId", {
            is: (typePersonId: number) => typePersonId === 2,
            then: schema => legalPersonSchema
        })
    });

    const validationSchema = object().shape({
        amount: number().min(1, "El importe debe ser superior a 0").required(),
        contractType: number().required(),
        signingAtNotary: boolean().when("contractType", {
            is: (contractType: number) => contractType === 0,
            then: schema => boolean().required()
        }),
        alternativeNotary: string().when(["contractType", "signingAtNotary"], {
            is: (contractType: number, signingAtNotary: boolean) => contractType === 0 && signingAtNotary === false,
            then: schema => string().required()
        }),
        signAPowerOfAttorneyToTaxman: boolean().when("contractType", {
            is: (contractType: number) => contractType === 0,
            then: schema => boolean().required()
        }),
        spanishNatitonality: boolean().test({
            name: "isSpanishNatitonality",
            test(value, ctx)
            {
                if (value !== true && ctx.parent.investor.typePersonId === 1)
                {
                    return ctx.createError({ message: "Contar con la nacionalidad española es un requisito indispensable" });
                }
                return true;
            }
        }),
        incomeOfEconomicActivities: boolean().test({
            name: "isIncomeOfEconomicActivities",
            test(value, ctx)
            {
                if (value !== true && ctx.parent.investor.typePersonId === 1)
                {
                    return ctx.createError({ message: "Contar con rendimientos de actividades económicas es un requisito indispensable" });
                }
                return true;
            }
        }),
        investor: investorSchema
    });



    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values: AnnualInvestmentCharterDto, actions: FormikHelpers<AnnualInvestmentCharterDto>) =>
        {
            values.amount = parseInt(values.amount.toString().replace(".", ""));

            actions.setSubmitting(true);
            setIsLoading(true);

            if (`${values.investor.typePersonId}` === "1")
            {
                delete values.investor.legalPersonInvestor;
            } else
            {
                delete values.investor.naturalPersonInvestor;
            }

            const contractName =
                `${formik.values.contractType}` === "0" ? AnnualAIEInvestmentCharterContractSlug : AnnualFinancingContractInvestmentCharterContractSlug;
            ContractDocServices.getLastContractVersion(contractName)
                .then((contractDocsVersionDto: ContractDocsVersionDto) =>
                {
                    setContractDocVersion(contractDocsVersionDto);
                    values.contractDocsVersionId = contractDocsVersionDto?.id;
                    const formData = new FormData();
                    const fileName = document["name"];
                    formData.append(fileName, document);
                    formData.append("data", JSON.stringify(values));

                        AnnualInvestmentCharterServices.postManualAnnualInvestmentCharter(formData)
                            .then((response: any) =>
                            {
                                if (typeof response == "number")
                                {
                                    setLetterCreatedModal(true);
                                    navigate("/letters");
                                }
                            })
                            .catch(error => { })
                            .finally(() =>
                            {
                                setIsLoading(false);
                            });
                    })
                    .catch(error => { });

        }
    });


    return (
        <>
            {isLoading && <Loading type="page" />}
            {!isLoading && (
                <ContentBox>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col className="marginTop32 textRight">
                                    <Button type="submit" disabled={formik.isSubmitting}>
                                        {formik.isSubmitting && <Loading type="buttonContent" />}
                                        Subir Carta
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}>
                                    <Field
                                        name="contractType"
                                        component={SelectField}
                                        onChange={formik.handleChange}
                                        value={formik.values.contractType}
                                        isInvalid={formik.errors.contractType && formik.touched.contractType}
                                        label="AIE / Contrato de financiación"
                                        placeholder="AIE / Contrato de financiación"
                                        nulloption={false}
                                    >
                                        {ContractTypes &&
                                            Object.keys(ContractTypes)
                                                .filter(v => isNaN(Number(v)))
                                                .map((key, index) => (
                                                    <option key={key} value={index}>
                                                        {key}
                                                    </option>
                                                ))}
                                    </Field>
                                    {formik.errors.contractType && formik.touched.contractType && (
                                        <span className={Styles.Error}>{formik.errors.contractType}</span>
                                    )}
                                </Col>

                            </Row>
                            <Row style={{ marginTop: "8px" }}>
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        name="investor.typePersonId"
                                        component={SelectField}
                                        onChange={formik.handleChange}
                                        value={formik.values.investor.typePersonId}
                                        isInvalid={formik.errors.investor?.typePersonId && formik.touched.investor?.typePersonId}
                                        label="Tipo de Persona"
                                        placeholder="Tipo de Persona"
                                        nulloption={false}
                                    >
                                        {investorTypePerson &&
                                            investorTypePerson.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Field>
                                    {formik.errors.investor?.typePersonId && formik.touched.investor?.typePersonId && (
                                        <span className={Styles.Error}>{formik.errors.investor.typePersonId}</span>
                                    )}
                                </Col>
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        name="investor.name"
                                        type="text"
                                        component={InputField}
                                        onChange={formik.handleChange}
                                        value={formik.values.investor.name}
                                        isInvalid={formik.errors.investor?.name && formik.touched.investor?.name}
                                        label={`${formik.values.investor.typePersonId}` === "1" ? "Nombre y apellidos" : "Denominación social"}
                                        placeholder={`${formik.values.investor.typePersonId}` === "1" ? "Nombre y apellidos" : "Denominación social"}
                                    />
                                    {formik.errors.investor?.name && formik.touched.investor?.name && (
                                        <span className={Styles.Error}>{formik.errors.investor.name}</span>
                                    )}
                                </Col>
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        name="investor.cif"
                                        type="text"
                                        component={InputField}
                                        onChange={formik.handleChange}
                                        value={formik.values.investor.cif}
                                        isInvalid={formik.errors.investor?.cif && formik.touched.investor?.cif}
                                        label={`${formik.values.investor.typePersonId}` === "1" ? "C.I.F." : "D.N.I."}
                                        placeholder={`${formik.values.investor.typePersonId}` === "1" ? "C.I.F." : "D.N.I."}
                                    />
                                    {formik.errors.investor?.cif && formik.touched.investor?.cif && (
                                        <span className={Styles.Error}>{formik.errors.investor.cif}</span>
                                    )}
                                </Col>
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        name="investor.address"
                                        type="text"
                                        component={InputField}
                                        onChange={formik.handleChange}
                                        value={formik.values.investor.address}
                                        isInvalid={formik.errors.investor?.address && formik.touched.investor?.address}
                                        label="Domicilio"
                                        placeholder="Domicilio"
                                    />
                                    {formik.errors.investor?.address && formik.touched.investor?.address && (
                                        <span className={Styles.Error}>{formik.errors.investor.address}</span>
                                    )}
                                </Col>
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        name="investor.postalCode"
                                        component={InputField}
                                        type="number"
                                        onChange={formik.handleChange}
                                        value={formik.values.investor.postalCode}
                                        isInvalid={formik.errors.investor?.postalCode && formik.touched.investor?.postalCode}
                                        label="Código Postal"
                                        placeholder="Código Postal"
                                    ></Field>
                                    {formik.errors.investor?.postalCode && formik.touched.investor?.postalCode && (
                                        <span className={Styles.Error}>{formik.errors.investor.postalCode}</span>
                                    )}
                                </Col>
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        name="investor.city"
                                        component={InputField}
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.investor.city}
                                        isInvalid={formik.errors.investor?.city && formik.touched.investor?.city}
                                        label="Ciudad"
                                        placeholder="Ciudad"
                                    ></Field>
                                    {formik.errors.investor?.city && formik.touched.investor?.city && (
                                        <span className={Styles.Error}>{formik.errors.investor.city}</span>
                                    )}
                                </Col>
                                {`${formik.values.investor.typePersonId}` === "1" && (
                                    <>
                                        <Col lg={3} style={{ marginTop: "8px" }}>
                                            <Field
                                                name="investor.naturalPersonInvestor.profession"
                                                component={InputField}
                                                onChange={formik.handleChange}
                                                value={formik.values.investor.naturalPersonInvestor?.profession}
                                                isInvalid={
                                                    (formik.errors.investor?.naturalPersonInvestor as any)?.profession &&
                                                    (formik.touched.investor?.naturalPersonInvestor as any)?.profession
                                                }
                                                label="Profesión"
                                                placeholder="Profesión"
                                            ></Field>
                                            {(formik.errors.investor?.naturalPersonInvestor as any)?.profession &&
                                                (formik.touched.investor?.naturalPersonInvestor as any)?.profession && (
                                                    <span className={Styles.Error}>
                                                        {(formik.errors.investor?.naturalPersonInvestor as any)?.profession}
                                                    </span>
                                                )}
                                        </Col>
                                        {`${formik.values.contractType}` === "0" && (
                                            <>
                                                <Col lg={3} style={{ marginTop: "8px" }}>
                                                    <Field
                                                        name="investor.naturalPersonInvestor.maritalStatus"
                                                        component={SelectField}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.investor.naturalPersonInvestor?.maritalStatus}
                                                        isInvalid={
                                                            (formik.errors.investor?.naturalPersonInvestor as any)?.maritalStatus &&
                                                            (formik.touched.investor?.naturalPersonInvestor as any)?.maritalStatus
                                                        }
                                                        label="Estado civil"
                                                    >
                                                        {maritalStatuses &&
                                                            Object.keys(maritalStatuses)
                                                                .filter(v => isNaN(Number(v)))
                                                                .map((key, index) => (
                                                                    <option key={key} value={index}>
                                                                        {key}
                                                                    </option>
                                                                ))}
                                                    </Field>
                                                    {(formik.errors.investor?.naturalPersonInvestor as any)?.maritalStatus &&
                                                        (formik.touched.investor?.naturalPersonInvestor as any)?.maritalStatus && (
                                                            <span className={Styles.Error}>
                                                                {(formik.errors.investor?.naturalPersonInvestor as any)?.maritalStatus}
                                                            </span>
                                                        )}
                                                </Col>
                                                {`${formik.values.investor.naturalPersonInvestor?.maritalStatus}` === "1" && (
                                                    <>
                                                        <Col lg={3}>
                                                            <Field
                                                                name="investor.naturalPersonInvestor.matrimonialRegime"
                                                                component={SelectField}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.investor.naturalPersonInvestor?.matrimonialRegime}
                                                                isInvalid={
                                                                    (formik.errors.investor?.naturalPersonInvestor as any)?.matrimonialRegime &&
                                                                    (formik.touched.investor?.naturalPersonInvestor as any)?.matrimonialRegime
                                                                }
                                                                label="Régimen matrimonial"
                                                            >
                                                                {matrimonialRegimes &&
                                                                    Object.keys(matrimonialRegimes)
                                                                        .filter(v => isNaN(Number(v)))
                                                                        .map((key, index) => (
                                                                            <option key={key} value={index}>
                                                                                {key}
                                                                            </option>
                                                                        ))}
                                                            </Field>
                                                            {(formik.errors.investor?.naturalPersonInvestor as any)?.matrimonialRegime &&
                                                                (formik.touched.investor?.naturalPersonInvestor as any)?.matrimonialRegime && (
                                                                    <span className={Styles.Error}>
                                                                        {(formik.errors.investor?.naturalPersonInvestor as any)?.matrimonialRegime}
                                                                    </span>
                                                                )}
                                                        </Col>
                                                        {`${formik.values.investor.naturalPersonInvestor?.matrimonialRegime}` === "0" && (
                                                            <>
                                                                <Col lg={3}>
                                                                    <Field
                                                                        name="investor.naturalPersonInvestor.spouseFullname"
                                                                        type="text"
                                                                        component={InputField}
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.investor.naturalPersonInvestor?.spouseFullname}
                                                                        isInvalid={
                                                                            (formik.errors.investor?.naturalPersonInvestor as any)?.spouseFullname &&
                                                                            (formik.touched.investor?.naturalPersonInvestor as any)?.spouseFullname
                                                                        }
                                                                        label="Nombre y Apellidos del cónyuge"
                                                                        placeholder="Nombre y Apellidos del cónyuge"
                                                                    />
                                                                    {(formik.errors.investor?.naturalPersonInvestor as any)?.spouseFullname &&
                                                                        (formik.touched.investor?.naturalPersonInvestor as any)?.spouseFullname && (
                                                                            <span className={Styles.Error}>
                                                                                {(formik.errors.investor?.naturalPersonInvestor as any)?.spouseFullname}
                                                                            </span>
                                                                        )}
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <Field
                                                                        name="investor.naturalPersonInvestor.spouseDocument"
                                                                        type="text"
                                                                        component={InputField}
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.investor.naturalPersonInvestor?.spouseDocument}
                                                                        isInvalid={
                                                                            (formik.errors.investor?.naturalPersonInvestor as any)?.spouseDocument &&
                                                                            (formik.touched.investor?.naturalPersonInvestor as any)?.spouseDocument
                                                                        }
                                                                        label="DNI del cónyuge"
                                                                        placeholder="DNI del cónyuge"
                                                                    />
                                                                    {(formik.errors.investor?.naturalPersonInvestor as any)?.spouseDocument &&
                                                                        (formik.touched.investor?.naturalPersonInvestor as any)?.spouseDocument && (
                                                                            <span className={Styles.Error}>
                                                                                {(formik.errors.investor?.naturalPersonInvestor as any)?.spouseDocument}
                                                                            </span>
                                                                        )}
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <Field
                                                                        name="investor.naturalPersonInvestor.spouseAddress"
                                                                        type="text"
                                                                        component={InputField}
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.investor.naturalPersonInvestor?.spouseAddress}
                                                                        isInvalid={
                                                                            (formik.errors.investor?.naturalPersonInvestor as any)?.spouseAddress &&
                                                                            (formik.touched.investor?.naturalPersonInvestor as any)?.spouseAddress
                                                                        }
                                                                        label="Dirección del cónyuge"
                                                                        placeholder="Dirección del cónyuge"
                                                                    />
                                                                    {(formik.errors.investor?.naturalPersonInvestor as any)?.spouseAddress &&
                                                                        (formik.touched.investor?.naturalPersonInvestor as any)?.spouseAddress && (
                                                                            <span className={Styles.Error}>
                                                                                {(formik.errors.investor?.naturalPersonInvestor as any)?.spouseAddress}
                                                                            </span>
                                                                        )}
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <Field
                                                                        name="investor.naturalPersonInvestor.spouseProfession"
                                                                        type="text"
                                                                        component={InputField}
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.investor.naturalPersonInvestor?.spouseProfession}
                                                                        isInvalid={
                                                                            (formik.errors.investor?.naturalPersonInvestor as any)?.spouseProfession &&
                                                                            (formik.touched.investor?.naturalPersonInvestor as any)?.spouseProfession
                                                                        }
                                                                        label="Profesión del cónyuge"
                                                                        placeholder="Profesión del cónyuge"
                                                                    />
                                                                    {(formik.errors.investor?.naturalPersonInvestor as any)?.spouseProfession &&
                                                                        (formik.touched.investor?.naturalPersonInvestor as any)?.spouseProfession && (
                                                                            <span className={Styles.Error}>
                                                                                {
                                                                                    (formik.errors.investor?.naturalPersonInvestor as any)
                                                                                        ?.spouseProfession
                                                                                }
                                                                            </span>
                                                                        )}
                                                                </Col>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {`${formik.values.investor.typePersonId}` === "2" && (
                                    <>
                                        <Col lg={3} style={{ marginTop: "8px" }}>
                                            <Field
                                                name="investor.legalPersonInvestor.cnae"
                                                component={InputField}
                                                onChange={formik.handleChange}
                                                value={formik.values.investor.legalPersonInvestor?.cnae}
                                                isInvalid={
                                                    (formik.errors.investor?.legalPersonInvestor as any)?.cnae &&
                                                    (formik.touched.investor?.legalPersonInvestor as any)?.cnae
                                                }
                                                label="CNAE"
                                                placeholder="CNAE"
                                            ></Field>
                                            {(formik.errors.investor?.legalPersonInvestor as any)?.cnae &&
                                                (formik.touched.investor?.legalPersonInvestor as any)?.cnae && (
                                                    <span className={Styles.Error}>{(formik.errors.investor?.legalPersonInvestor as any)?.cnae}</span>
                                                )}
                                        </Col>
                                        <Col lg={3}>
                                            <Field
                                                name="investor.legalPersonInvestor.fiscalYearEndDate"
                                                type="date"
                                                component={InputField}
                                                onChange={formik.handleChange}
                                                value={formik.values.investor.legalPersonInvestor?.fiscalYearEndDate}
                                                isInvalid={
                                                    (formik.errors.investor?.legalPersonInvestor as any)?.fiscalYearEndDate &&
                                                    (formik.touched.investor?.legalPersonInvestor as any)?.fiscalYearEndDate
                                                }
                                                label="Fecha de finalización ejercicio"
                                                placeholder="Fecha de finalización ejercicio"
                                            ></Field>
                                            {(formik.errors.investor?.legalPersonInvestor as any)?.fiscalYearEndDate &&
                                                (formik.touched.investor?.legalPersonInvestor as any)?.fiscalYearEndDate && (
                                                    <span className={Styles.Error}>
                                                        {(formik.errors.investor?.legalPersonInvestor as any)?.fiscalYearEndDate}
                                                    </span>
                                                )}
                                        </Col>
                                    </>
                                )}
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        component={InputField}
                                        type="text"
                                        name="amount"
                                        label="Importe de la inversión (€)"
                                        placeholder="Importe de la inversión"
                                        suffix={" €"}
                                        onChange={formik.handleChange}
                                        value={formik.values.amount}
                                        isInvalid={formik.errors.amount && formik.touched.amount}
                                    />
                                    {formik.errors.amount && formik.touched.amount && <span className={Styles.Error}>{formik.errors.amount}</span>}
                                </Col>
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        name="investor.countryPrefix"
                                        component={SelectField}
                                        onChange={formik.handleChange}
                                        value={formik.values.investor.countryPrefix}
                                        isInvalid={formik.errors.investor?.countryPrefix && formik.touched.investor?.countryPrefix}
                                        label="Prefijo"
                                    >
                                        {CountryPrefixes &&
                                            CountryPrefixes.map((prefix, index) => (
                                                <option key={prefix.key} value={prefix.phone_code}>
                                                    {prefix.name} {prefix.phone_code}
                                                </option>
                                            ))}
                                    </Field>
                                    {formik.errors.investor?.countryPrefix && formik.touched.investor?.countryPrefix && (
                                        <span className={Styles.Error}>{formik.errors.investor.countryPrefix}</span>
                                    )}
                                </Col>
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        name="investor.phone"
                                        component={InputField}
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.investor.phone}
                                        isInvalid={formik.errors.investor?.phone && formik.touched.investor?.phone}
                                        label="Teléfono"
                                        placeholder="Teléfono"
                                    ></Field>
                                    {formik.errors.investor?.phone && formik.touched.investor?.phone && (
                                        <span className={Styles.Error}>{formik.errors.investor.phone}</span>
                                    )}
                                </Col>
                                <Col lg={3} style={{ marginTop: "8px" }}>
                                    <Field
                                        disabled={false}
                                        name="investor.email"
                                        type="text"
                                        component={InputField}
                                        onChange={formik.handleChange}
                                        value={formik.values.investor.email}
                                        isInvalid={formik.errors.investor?.email && formik.touched.investor?.email}
                                        label="Email del Inversor"
                                        placeholder="Email del Inversor"
                                    />
                                    {formik.errors.investor?.email && formik.touched.investor?.email && (
                                        <span className={Styles.Error}>{formik.errors.investor.email}</span>
                                    )}
                                </Col>
                                {`${formik.values.contractType}` === "0" && (
                                    <>
                                        <Col sm={12} style={{ marginTop: "10px" }} className={`${Styles.ColField} ${Styles.Spaced}`}>
                                            <Row>
                                                <Col>
                                                    Las operaciones que se efectúen durante el año {contractDocVersion?.version} y que se deban elevar
                                                    público se firmarán ante notarios de Madrid y Sevilla. En su caso, ¿desea acudir a la firma en
                                                    notaría?
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <label>
                                                        <Field
                                                            type="Radio"
                                                            name="signingAtNotary"
                                                            onChange={formik.handleChange}
                                                            value={true}
                                                            style={{ marginRight: "5px" }}
                                                            checked={formik.values.signingAtNotary?.toString() === "true"}
                                                            isInvalid={formik.errors.signingAtNotary && formik.touched.signingAtNotary}
                                                        />
                                                        Si
                                                    </label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <label>
                                                        <Field
                                                            type="Radio"
                                                            name="signingAtNotary"
                                                            onChange={formik.handleChange}
                                                            value={false}
                                                            style={{ marginRight: "5px" }}
                                                            checked={formik.values.signingAtNotary?.toString() === "false"}
                                                            isInvalid={formik.errors.signingAtNotary && formik.touched.signingAtNotary}
                                                        />
                                                        No
                                                    </label>
                                                </Col>
                                            </Row>
                                            {formik.errors.signingAtNotary && formik.touched.signingAtNotary && (
                                                <span className={Styles.Error}>{formik.errors.signingAtNotary}</span>
                                            )}
                                        </Col>
                                        {`${formik.values.signingAtNotary}` === "false" && (
                                            <Col sm={12}>
                                                <Row>
                                                    <Col sm={12}>
                                                        <label>
                                                            En caso de no encontrarse en Madrid/Sevilla o no poder acudir a la firma, por favor indique
                                                            en qué notaría puede ratificar:
                                                        </label>
                                                    </Col>
                                                    <Col xl={6}>
                                                        <Field
                                                            name="alternativeNotary"
                                                            component={InputField}
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.alternativeNotary}
                                                            isInvalid={formik.errors.alternativeNotary && formik.touched.alternativeNotary}
                                                            label={false}
                                                            placeholder="Notaría alternativa"
                                                        ></Field>
                                                    </Col>
                                                </Row>
                                                {formik.errors.alternativeNotary && formik.touched.alternativeNotary && (
                                                    <span className={Styles.Error}>{formik.errors.alternativeNotary}</span>
                                                )}
                                            </Col>
                                        )}
                                        <Col sm={12} className={`${Styles.ColField} ${Styles.Spaced}`}>
                                            <Row>
                                                <Col>
                                                    ¿Quiere firmar un poder a favor de los integrantes de TAXMM para que, en el momento de la salida de
                                                    la inversión, puedan firmar por usted?
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <label>
                                                        <Field
                                                            type="Radio"
                                                            name="signAPowerOfAttorneyToTaxman"
                                                            onChange={formik.handleChange}
                                                            value={true}
                                                            checked={formik.values.signAPowerOfAttorneyToTaxman?.toString() === "true"}
                                                            isInvalid={
                                                                formik.errors.signAPowerOfAttorneyToTaxman &&
                                                                formik.touched.signAPowerOfAttorneyToTaxman
                                                            }
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                        Si
                                                    </label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <label>
                                                        <Field
                                                            type="Radio"
                                                            name="signAPowerOfAttorneyToTaxman"
                                                            onChange={formik.handleChange}
                                                            value={false}
                                                            checked={formik.values.signAPowerOfAttorneyToTaxman?.toString() === "false"}
                                                            isInvalid={
                                                                formik.errors.signAPowerOfAttorneyToTaxman &&
                                                                formik.touched.signAPowerOfAttorneyToTaxman
                                                            }
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                        No
                                                    </label>
                                                </Col>
                                            </Row>
                                            {formik.errors.signAPowerOfAttorneyToTaxman && formik.touched.signAPowerOfAttorneyToTaxman && (
                                                <span className={Styles.Error}>{formik.errors.signAPowerOfAttorneyToTaxman}</span>
                                            )}
                                        </Col>
                                    </>
                                )}
                                {`${formik.values.investor.typePersonId}` === "2" && (
                                    <>
                                        <Col sm={12} className={`${Styles.ColField} ${Styles.Spaced}`}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label>
                                                        {`Indique su facturación (€) en el ejercicio ${contractDocVersion?.version ?? ""
                                                            } (necesaria para calcular la inversión óptima teniendo en cuenta la tributación mínima establecida en el artículo 30 bis LIS)`}
                                                    </label>
                                                </Col>
                                                <Col sm={12} md={6} xl={3}>
                                                    <Field
                                                        component={InputField}
                                                        type="number"
                                                        name="investor.legalPersonInvestor.annualBilling"
                                                        label={false}
                                                        placeholder={`Facturación ${contractDocVersion?.version ?? ""}`}
                                                        suffix={" €"}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.investor.legalPersonInvestor?.annualBilling}
                                                        isInvalid={
                                                            (formik.errors.investor?.legalPersonInvestor as any)?.annualBilling &&
                                                            (formik.touched.investor?.legalPersonInvestor as any)?.annualBilling
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            {(formik.errors.investor?.legalPersonInvestor as any)?.annualBilling &&
                                                (formik.touched.investor?.legalPersonInvestor as any)?.annualBilling && (
                                                    <span className={Styles.Error}>
                                                        {(formik.errors.investor?.legalPersonInvestor as any)?.annualBilling}
                                                    </span>
                                                )}
                                        </Col>
                                        <Col lg={3}>
                                            <Field
                                                name="investor.legalPersonInvestor.representativePosition"
                                                component={SelectField}
                                                onChange={formik.handleChange}
                                                value={formik.values.investor.legalPersonInvestor?.representativePosition}
                                                isInvalid={
                                                    (formik.errors.investor?.legalPersonInvestor as any)?.representativePosition &&
                                                    (formik.touched.investor?.legalPersonInvestor as any)?.representativePosition
                                                }
                                                label="Cargo de representación"
                                            >
                                                {representativePositions &&
                                                    Object.keys(representativePositions)
                                                        .filter(v => isNaN(Number(v)))
                                                        .map((key, index) => (
                                                            <option key={key} value={index}>
                                                                {key}
                                                            </option>
                                                        ))}
                                            </Field>
                                            {(formik.errors.investor?.legalPersonInvestor as any)?.representativePosition &&
                                                (formik.touched.investor?.legalPersonInvestor as any)?.representativePosition && (
                                                    <span className={Styles.Error}>
                                                        {(formik.errors.investor?.legalPersonInvestor as any)?.representativePosition}
                                                    </span>
                                                )}
                                        </Col>
                                        {`${formik.values.investor?.legalPersonInvestor?.representativePosition}` !== "2" && (
                                            <Row>
                                                <fieldset>
                                                    <legend>Representante:</legend>
                                                    <Col lg={3}>
                                                        <Field
                                                            name={`investor.legalPersonInvestor.representatives.0.fullname`}
                                                            component={InputField}
                                                            onChange={formik.handleChange}
                                                            value={(formik.values.investor.legalPersonInvestor?.representatives || [])[0].fullname}
                                                            label="Nombre completo"
                                                            placeholder="Nombre completo"
                                                            isInvalid={
                                                                (formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.fullname &&
                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[0]?.fullname
                                                            }
                                                        ></Field>
                                                        {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                            (formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.fullname &&
                                                            formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives[0]?.fullname && (
                                                                <span className={Styles.Error}>
                                                                    {(formik.errors.investor?.legalPersonInvestor as any).representatives[0].fullname}
                                                                </span>
                                                            )}
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Field
                                                            name={`investor.legalPersonInvestor.representatives.0.document`}
                                                            onChange={formik.handleChange}
                                                            value={(formik.values.investor.legalPersonInvestor?.representatives || [])[0].document}
                                                            component={InputField}
                                                            label="DNI"
                                                            placeholder="DNI"
                                                            isInvalid={
                                                                (formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.document &&
                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[0].document
                                                            }
                                                        ></Field>
                                                        {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                            (formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.document &&
                                                            formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives[0]?.document && (
                                                                <span className={Styles.Error}>
                                                                    {(formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.document}
                                                                </span>
                                                            )}
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Field
                                                            name={`investor.legalPersonInvestor.representatives.0.countryPrefix`}
                                                            onChange={formik.handleChange}
                                                            value={(formik.values.investor.legalPersonInvestor?.representatives || [])[0].countryPrefix}
                                                            component={SelectField}
                                                            label="Prefijo"
                                                            isInvalid={
                                                                (formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[0]
                                                                    ?.countryPrefix &&
                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[0]?.countryPrefix
                                                            }
                                                        >
                                                            {CountryPrefixes &&
                                                                CountryPrefixes.map((prefix, index) => (
                                                                    <option key={prefix.key} value={prefix.phone_code}>
                                                                        {prefix.name} {prefix.phone_code}
                                                                    </option>
                                                                ))}
                                                        </Field>
                                                        {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                            (formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.countryPrefix &&
                                                            formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives[0]?.countryPrefix && (
                                                                <span className={Styles.Error}>
                                                                    {
                                                                        (formik.errors.investor?.legalPersonInvestor as any).representatives[0]
                                                                            ?.countryPrefix
                                                                    }
                                                                </span>
                                                            )}
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Field
                                                            name={`investor.legalPersonInvestor.representatives.0.phone`}
                                                            onChange={formik.handleChange}
                                                            value={(formik.values.investor.legalPersonInvestor?.representatives || [])[0].phone}
                                                            component={InputField}
                                                            label="Teléfono"
                                                            placeholder="Teléfono"
                                                            isInvalid={
                                                                (formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.phone &&
                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[0]?.phone
                                                            }
                                                        ></Field>
                                                        {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                            (formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.phone &&
                                                            formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives[0]?.phone && (
                                                                <span className={Styles.Error}>
                                                                    {(formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.phone}
                                                                </span>
                                                            )}
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Field
                                                            name={`investor.legalPersonInvestor.representatives.0.email`}
                                                            onChange={formik.handleChange}
                                                            value={(formik.values.investor.legalPersonInvestor?.representatives || [])[0].email}
                                                            component={InputField}
                                                            label="Email"
                                                            placeholder="Email"
                                                            isInvalid={
                                                                (formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.email &&
                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[0]?.email
                                                            }
                                                        ></Field>
                                                        {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !== undefined &&
                                                            (formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.email &&
                                                            formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives !== undefined &&
                                                            (formik.touched.investor?.legalPersonInvestor as any).representatives[0]?.email && (
                                                                <span className={Styles.Error}>
                                                                    {(formik.errors.investor?.legalPersonInvestor as any).representatives[0]?.email}
                                                                </span>
                                                            )}
                                                    </Col>
                                                </fieldset>
                                            </Row>
                                        )}
                                        {`${formik.values.investor?.legalPersonInvestor?.representativePosition}` === "2" && (
                                            <FieldArray
                                                name="investor.legalPersonInvestor.representatives"
                                                render={arrayHelpers => (
                                                    <>
                                                        <fieldset>
                                                            <legend>Representantes:</legend>
                                                            {formik.values.investor?.legalPersonInvestor?.representatives?.map(
                                                                (representative, index) => (
                                                                    <Row>
                                                                        <Col lg={3}>
                                                                            <Field
                                                                                name={`investor.legalPersonInvestor.representatives.${index}.fullname`}
                                                                                onChange={formik.handleChange}
                                                                                value={
                                                                                    (formik.values.investor.legalPersonInvestor?.representatives || [])[
                                                                                        index
                                                                                    ].fullname
                                                                                }
                                                                                component={InputField}
                                                                                label="Nombre completo"
                                                                                placeholder="Nombre completo"
                                                                                isInvalid={
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        ?.representatives !== undefined &&
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.fullname &&
                                                                                    formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.fullname
                                                                                }
                                                                            ></Field>
                                                                            {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !==
                                                                                undefined &&
                                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.fullname &&
                                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any)
                                                                                    .representatives !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.fullname && (
                                                                                    <span className={Styles.Error}>
                                                                                        {
                                                                                            (formik.errors.investor?.legalPersonInvestor as any)
                                                                                                .representatives[index]?.fullname
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                        </Col>
                                                                        <Col lg={3}>
                                                                            <Field
                                                                                name={`investor.legalPersonInvestor.representatives.${index}.document`}
                                                                                onChange={formik.handleChange}
                                                                                value={
                                                                                    (formik.values.investor.legalPersonInvestor?.representatives || [])[
                                                                                        index
                                                                                    ].document
                                                                                }
                                                                                component={InputField}
                                                                                label="DNI"
                                                                                placeholder="DNI"
                                                                                isInvalid={
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        ?.representatives !== undefined &&
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.document &&
                                                                                    formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.document
                                                                                }
                                                                            ></Field>
                                                                            {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !==
                                                                                undefined &&
                                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.document &&
                                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any)
                                                                                    .representatives !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.document && (
                                                                                    <span className={Styles.Error}>
                                                                                        {
                                                                                            (formik.errors.investor?.legalPersonInvestor as any)
                                                                                                .representatives[index]?.document
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                        </Col>
                                                                        <Col lg={2}>
                                                                            <Field
                                                                                name={`investor.legalPersonInvestor.representatives.${index}.countryPrefix`}
                                                                                onChange={formik.handleChange}
                                                                                value={
                                                                                    (formik.values.investor.legalPersonInvestor?.representatives || [])[
                                                                                        index
                                                                                    ].countryPrefix
                                                                                }
                                                                                component={SelectField}
                                                                                label="Prefijo"
                                                                                isInvalid={
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        ?.representatives !== undefined &&
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.countryPrefix &&
                                                                                    formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.countryPrefix
                                                                                }
                                                                            >
                                                                                {CountryPrefixes &&
                                                                                    CountryPrefixes.map((prefix, index) => (
                                                                                        <option key={prefix.key} value={prefix.phone_code}>
                                                                                            {prefix.name} {prefix.phone_code}
                                                                                        </option>
                                                                                    ))}
                                                                            </Field>
                                                                            {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !==
                                                                                undefined &&
                                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.countryPrefix &&
                                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any)
                                                                                    .representatives !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.countryPrefix && (
                                                                                    <span className={Styles.Error}>
                                                                                        {
                                                                                            (formik.errors.investor?.legalPersonInvestor as any)
                                                                                                .representatives[index]?.countryPrefix
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                        </Col>
                                                                        <Col lg={2}>
                                                                            <Field
                                                                                name={`investor.legalPersonInvestor.representatives.${index}.phone`}
                                                                                onChange={formik.handleChange}
                                                                                value={
                                                                                    (formik.values.investor.legalPersonInvestor?.representatives || [])[
                                                                                        index
                                                                                    ].phone
                                                                                }
                                                                                component={InputField}
                                                                                label="Teléfono"
                                                                                placeholder="Teléfono"
                                                                                isInvalid={
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        ?.representatives !== undefined &&
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.phone &&
                                                                                    formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.phone
                                                                                }
                                                                            ></Field>
                                                                            {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !==
                                                                                undefined &&
                                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.phone &&
                                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any)
                                                                                    .representatives !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.phone && (
                                                                                    <span className={Styles.Error}>
                                                                                        {
                                                                                            (formik.errors.investor?.legalPersonInvestor as any)
                                                                                                .representatives[index]?.phone
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                        </Col>
                                                                        <Col lg={3}>
                                                                            <Field
                                                                                name={`investor.legalPersonInvestor.representatives.${index}.email`}
                                                                                onChange={formik.handleChange}
                                                                                value={
                                                                                    (formik.values.investor.legalPersonInvestor?.representatives || [])[
                                                                                        index
                                                                                    ].email
                                                                                }
                                                                                component={InputField}
                                                                                label="Email"
                                                                                placeholder="Email"
                                                                                isInvalid={
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        ?.representatives !== undefined &&
                                                                                    (formik.errors.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.email &&
                                                                                    formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives !== undefined &&
                                                                                    (formik.touched.investor?.legalPersonInvestor as any)
                                                                                        .representatives[index]?.email
                                                                                }
                                                                            ></Field>
                                                                            {(formik.errors.investor?.legalPersonInvestor as any)?.representatives !==
                                                                                undefined &&
                                                                                (formik.errors.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.email &&
                                                                                formik.touched.investor?.legalPersonInvestor !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any)
                                                                                    .representatives !== undefined &&
                                                                                (formik.touched.investor?.legalPersonInvestor as any).representatives[
                                                                                    index
                                                                                ]?.email && (
                                                                                    <span className={Styles.Error}>
                                                                                        {
                                                                                            (formik.errors.investor?.legalPersonInvestor as any)
                                                                                                .representatives[index]?.email
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                        </Col>
                                                                        <Col lg={1}>
                                                                            {index > 0 && (
                                                                                <button type="button" onClick={() => arrayHelpers.remove(index)}>
                                                                                    -
                                                                                </button>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            )}
                                                        </fieldset>
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                arrayHelpers.push({
                                                                    fullname: "",
                                                                    document: "",
                                                                    countryPrefix: "+34",
                                                                    phone: "",
                                                                    email: ""
                                                                })
                                                            }
                                                        >
                                                            Añadir representante
                                                        </button>
                                                    </>
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                                {`${formik.values.investor.typePersonId}` === "1" && (
                                    <>
                                        <Col sm={12} className={`${Styles.ColField} ${Styles.Spaced}`}>
                                            <label>
                                                <Field
                                                    type="Checkbox"
                                                    name="spanishNatitonality"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.spanishNatitonality}
                                                    isInvalid={formik.errors.spanishNatitonality && formik.touched.spanishNatitonality}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                Declaro responsablemente que, a la fecha de la firma de la presente carta de reserva de inversión,
                                                cuento con nacionalidad española
                                            </label>
                                            {formik.errors.spanishNatitonality && formik.touched.spanishNatitonality && (
                                                <span className={Styles.Error}>{formik.errors.spanishNatitonality}</span>
                                            )}
                                        </Col>
                                        <Col sm={12}>
                                            <label>
                                                <Field
                                                    type="Checkbox"
                                                    name="incomeOfEconomicActivities"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.incomeOfEconomicActivities}
                                                    isInvalid={formik.errors.incomeOfEconomicActivities && formik.touched.incomeOfEconomicActivities}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                Declaro responsablemente que actualmente obtengo rendimientos procedentes de actividades económicas
                                            </label>
                                            {formik.errors.incomeOfEconomicActivities && formik.touched.incomeOfEconomicActivities && (
                                                <span className={Styles.Error}>{formik.errors.incomeOfEconomicActivities}</span>
                                            )}
                                        </Col>
                                    </>
                                )}
                            </Row>

                        </form>
                    </FormikProvider>
                </ContentBox>
            )}

            <BasicModalWithoutCancel
                title={"Carta creada"}
                body={
                    <>
                        <p>La carta ha sido creada. Pulsa el botón aceptar para volver al listado de cartas.</p>
                    </>
                }
                btnSubmitText="Aceptar"
                showModal={letterCreatedModal}
                onSubmit={() =>
                {
                    setLetterCreatedModal(false);
                    navigate("/letters")
                }}
                onClose={() =>
                {
                    setLetterCreatedModal(false);
                    navigate("/letters")
                }}
            />
        </>


    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updatePageHeaderTitle: (title: string) => dispatch(actions.updatePageHeaderTitle(title))
});

export default connect(null, mapDispatchToProps)(LetterCreationStepThree);
