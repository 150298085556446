import React from "react";
import { AttachmentsResponse } from "../../services/attachmentServices";
import IconButton from "../IconButtonWithTooltip";
import { DeleteIcon, DownloadIcon } from "@fluentui/react-icons-mdl2";
import Loading from "../Loading";

type Props = {
    doc: AttachmentsResponse;
    fileNamePadding?: string;
    onClick: (e: any, doc: AttachmentsResponse) => void;
    onDeleteClick: (e: any, doc: AttachmentsResponse) => void;
};

const AttachmentRowNoPadding = (props: Props) =>
{
    const { doc, onClick, onDeleteClick, fileNamePadding } = props;
    const [isDisabled, setIsDisabled] = React.useState(false);

    const handleClick = (e: React.MouseEvent) =>
    {
        if (!isDisabled)
        {
            onClick(e, doc);

            setTimeout(() =>
            {
                setIsDisabled(false);
            }, 2000);
        }
    };

    return (
        <tr>
            {isDisabled && (
                <td colSpan={4}>
                    <Loading type="component"></Loading>
                </td>
            )}
            {!isDisabled && (
                <>
                    <td>{doc.fileName}</td>
                    <td style={{ padding: "5px 32px 5px 32px" }}>{doc.year}</td>
                    <td>
                        <a
                            href={doc.documentURL}
                            target="_blank"
                            rel="noreferrer"
                            download
                            onClick={(e: any) =>
                            {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsDisabled(true);
                                handleClick(e);
                            }}
                        >
                            <IconButton id={doc.id} tooltipPlacement="top" tooltip="Descargar" icon={<DownloadIcon className="AppIcon" />} />
                        </a>
                    </td>
                    <td>
                        <IconButton
                            id={"doc-" + doc.id}
                            tooltipPlacement="top"
                            tooltip="Eliminar"
                            onClick={(e: any) => onDeleteClick(e, doc)}
                            icon={<DeleteIcon className="AppIcon" />}
                        />
                    </td>
                </>
            )}
        </tr>
    );
};

export default AttachmentRowNoPadding;
