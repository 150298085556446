import axios from "axios";
import config from "../config";
import { AttachmentsResponse } from "./attachmentServices";
import { ProductDto } from "./productServices";
import { InvestorDto } from "./investorServices";
const proxy: string | undefined = config.PROXY_URL;

export enum OpportunityStatuses {
	New,
	PendingProducerSignature,
	PendingInvestorsSignatures,
	Completed,
	ContractCreated
}

export type OpportunityDto = {
	id?: number;
	investorId: number;
	productId: number;
	investor?: InvestorDto;
	product?: ProductDto;
	commitment?: number;
	profitability?: number;
	year?: number;
	taxCreditPercentage?: number;
	status: OpportunityStatuses;
};


export type DocumentProduct = {
    idsOpportunities: number[];
    idsDocuments: number[];
};


export type OpportunityDocumentCategoryDto = {
	id: number;
	name: string;
	opportunityFiles: AttachmentsResponse[];
};

export type ModelEmailInversionDto = {
	investor: number;
    inversion: number;
    ibanAportation: string;
    amountAportation: number;
    ibanTrade: string;
    amountTrade: string;
};

export type ModelEmailAieDto = {
	investorId?: number;
    productId?: number;
    investorName?: string;
    inversion?: number;
    ibanAportation?: string;
    amountAportation?: number;
    ibanTrade?: number;
    amountTrade?: number;
};


class OpportunityServices {
	public getOpportunities = async (filters?: { email?: string; status: OpportunityStatuses; productId?: string }): Promise<OpportunityDto[]> => {
		let url = `${proxy}/opportunities?hasClaims=true`;

		if (filters !== undefined && filters !== null) {
			if (filters?.email) {
				url += `&email=${filters.email}`;
			}

			if (filters?.status !== undefined) {
				url += `&status=${filters.status}`;
			}

			if (filters?.productId !== undefined) {
				url += `&productId=${filters.productId}`;
			}
		}

		const req = await axios.get(url);
		return req.data;
	};

	public getOpportunity = async (opportunityId: number): Promise<OpportunityDto> => {
		let url = `${proxy}/opportunity/${opportunityId}`;
		const req = await axios.get(url);
		return req.data;
	};

	public deleteOpportunity = async (opportunityId: number): Promise<any> => {
		let url = `${proxy}/opportunity/${opportunityId}`;
		const req = await axios.delete(url);
		return req.data;
	};

	public postOpportunities = async (opportunities: OpportunityDto[]): Promise<OpportunityDto[]> => {
		let url = `${proxy}/opportunities`;
		const req = await axios.post(url, opportunities);
		return req.data;
	};

	public postDocumentProducts = async (documentProduct: DocumentProduct): Promise<any> => {
		let url = `${proxy}/opportunities/documentbyproduct`;
		const req = await axios.post(url, documentProduct);
		return req.data;
	};

    public postOpportunityEmailAie = async (modelEmail: ModelEmailAieDto): Promise<any> => {
		let url = `${proxy}/opportunity/email/1`;
		const req = await axios.post(url, modelEmail);
		return req.data;
	};
    public postOpportunityEmailFin = async (modelEmail: ModelEmailAieDto): Promise<any> => {
		let url = `${proxy}/opportunity/email/0`;
		const req = await axios.post(url, modelEmail);
		return req.data;
	};
	public putOpportunity = async (opportunityId: number, opportunity: OpportunityDto): Promise<OpportunityDto> => {
		let url = `${proxy}/opportunity/${opportunityId}`;
		const req = await axios.put(url, opportunity);
		return req.data;
	};

	public getOpportunityAttachments = async (id: number): Promise<OpportunityDocumentCategoryDto[]> => {
		let url = `${proxy}/opportunity/${id}/documents`;
		const req = await axios.get(url);
		return req.data;
	};

	public deleteOpportunityAttachments = async (id: number, filesIds: number[]): Promise<number[]> => {
		let url = `${proxy}/opportunity/${id}/documents`;
		const req = await axios.delete(url);
		return req.data;
	};

	public addOpportunityAttachments = async (id: number, formData: FormData, categoryId: number, year?: number): Promise<string[]> => {
		let url = `${proxy}/opportunity/${id}/documents?CategoryId=${categoryId}`;

		if (year) {
			url += `&Year=${year}`;
		}

		const req = await axios.post(url, formData);
		return req.data;
	};

	public getOpportunityDocumentCategories = async (): Promise<OpportunityDocumentCategoryDto[]> => {
		let url = `${proxy}/opportunities/document/categories`;
		const req = await axios.get(url);
		return req.data;
	};

	public opportunityToContract = async (id: number): Promise<OpportunityDocumentCategoryDto[]> => {
		let url = `${proxy}/opportunity/${id}/create-contract`;
		const req = await axios.post(url);
		return req.data;
	};

	public downloadDocument = async (opportunityId: number, documentId: number): Promise<any> => {
		let url = `${proxy}/opportunity/${opportunityId}/document/${documentId}`;
		const req = await axios.get(url, { responseType: "blob" });
		return req;
	};
}

const OpportunityService = new OpportunityServices();
export default OpportunityService;
