import axios from "axios";
import config from "../config";
import { CoProducerDto } from "./coProducerServices";
import { ContractDto } from "./contractServices";
import { OpportunityDto } from "./opportunityServices";
import { AdministratorProductDto } from "./administratorServices";
import { AttachmentsResponse } from "./attachmentServices";
const proxy: string | undefined = config.PROXY_URL;

export type ProductDto = {
	id?: number;
	year?: number;
	aieFinanc?: string;
	address?: string;
	name?: string;
	cif?: string;
	productTypeId?: number;
	administratorsProducts?: AdministratorProductDto[];
	cnae?: number;
	event?: string;
	emailAie?: string;
	phoneAie?: number;
	producerAccessEmail?: string;
	expectedExpenses?: number;
	contactTaxmmId?: number;
	netFee?: number;
	deduction?: number;
	auditor?: string;
	amountCREA?: number;
	baseDeduction?: number;
	result?: number;
	payOut?: number;
	iban?: string;
	taxCreditGenerated?: number;
	totalInvestmentReceived?: number;
	createdAt?: string;
	updatedAt?: string;
    producerId?: number;
    capitalToRise?: number;
};

export type DocumentsByCategoryResponse = {
	id: number;
	name: string;
	productFiles: AttachmentsResponse[];
};

export type ProductsDocumentCategories = {
	id: number;
	name: string;
};

export type ProductTypeDto = {
	id: number;
	name: string;
};

export type ProductAmountsDto = {
	productId: number;
	totalTaxCreditGenerated: number;
	totalTaxCreditContracted: number;
	totalPending: number;
};

export type ProductCoProducerDto = {
	id?: number;
	productId: number;
	product?: ProductDto;
	coProducerId: number;
	coProducer?: CoProducerDto;
	createdAt?: string;
	updatedAt?: string;
};

export type ProductTaskDto = {
	id?: number;
	text?: string;
	productId: number;
	taskDate?: string;
	product?: ProductTypeDto;
	createdAt?: string;
	updatedAt?: string;
};

class ProductServices {
	public getProduct = async (productId: number): Promise<ProductDto> => {
		let url = `${proxy}/product/${productId}`;
		const req = await axios.get(url);
		return req.data;
	};

	public postProduct = async (product: ProductDto): Promise<ProductDto> => {
		let url = `${proxy}/product/0`;
		const req = await axios.post(url, product);
		return req.data;
	};

	public putProduct = async (productId: number, product: ProductDto): Promise<ProductDto> => {
		let url = `${proxy}/product/${productId}`;
		const req = await axios.put(url, product);
		return req.data;
	};

	public deleteProduct = async (productId: number): Promise<any> => {
		let url = `${proxy}/product/${productId}`;
		const req = await axios.delete(url);
		return req.data;
	};

	public duplicateProduct = async (productId: number): Promise<ProductDto> => {
		let url = `${proxy}/product/${productId}/duplicate`;
		const req = await axios.post(url);
		return req.data;
	};

	public getProducts = async (): Promise<ProductDto[]> => {
		let url = `${proxy}/products?hasClaims=true`;
		const req = await axios.get(url);
		return req.data;
	};

	public getProductAttachments = async (id: number, categoryName?: string | undefined): Promise<DocumentsByCategoryResponse[]> => {
		let url = `${proxy}/product/${id}/documents`;

		if (categoryName) {
			url = url + `?category=${encodeURIComponent(categoryName)}`;
		}

		const req = await axios.get(url);
		return req.data;
	};

	public deleteProductAttachments = async (id: number, filesIds: number[]): Promise<number[]> => {
		let url = `${proxy}/product/${id}/documents`;
		const req = await axios.delete(url, { data: filesIds });
		return req.data;
	};

	public addProductAttachments = async (id: number, formData: FormData, categoryId: number, year?: number): Promise<string[]> => {
		let url = `${proxy}/product/${id}/documents?CategoryId=${categoryId}`;

		if (year) {
			url += `&Year=${year}`;
		}

		const req = await axios.post(url, formData);
		return req.data;
	};

	public getProductTypes = async (): Promise<ProductTypeDto[]> => {
		let url = `${proxy}/product/types`;
		const req = await axios.get(url);
		return req.data;
	};

	public getProductDocumentCategories = async (): Promise<ProductsDocumentCategories[]> => {
		let url = `${proxy}/product/document/categories`;
		const req = await axios.get(url);
		return req.data;
	};

	public getProductOpportunities = async (productId: number): Promise<OpportunityDto[]> => {
		let url = `${proxy}/product/${productId}/opportunities`;
		const req = await axios.get(url);
		return req.data;
	};

	public getProductContracts = async (productId: number): Promise<ContractDto[]> => {
		let url = `${proxy}/product/${productId}/contracts`;
		const req = await axios.get(url);
		return req.data;
	};

	public getProductCoProducers = async (productId: number): Promise<ProductCoProducerDto[]> => {
		let url = `${proxy}/product/${productId}/co-producers`;
		const req = await axios.get(url);
		return req.data;
	};

	public addProductCoProducer = async (productId: number, coProducerId: number): Promise<any> => {
		let url = `${proxy}/product/${productId}/co-producer/${coProducerId}`;
		const req = await axios.post(url);
		return req.data;
	};

	public deleteProductCoProducer = async (productId: number, coProducerId: number): Promise<any> => {
		let url = `${proxy}/product/${productId}/co-producer/${coProducerId}`;
		const req = await axios.delete(url);
		return req.data;
	};

	public getProductAmounts = async (productId: number): Promise<ProductAmountsDto> => {
		let url = `${proxy}/product/${productId}/amounts`;
		const req = await axios.get(url);
		return req.data;
	};

	public downloadDocument = async (productId: number, documentId: number): Promise<any> => {
		let url = `${proxy}/product/${productId}/document/${documentId}`;
		const req = await axios.get(url, { responseType: "blob" });
		return req;
	};

	public getProductTasks = async (productId: number): Promise<ProductTaskDto[]> => {
		let url = `${proxy}/product/${productId}/tasks`;
		const req = await axios.get(url);
		return req.data;
	};

	public deleteProductTask = async (productId: number, taskId: number): Promise<any> => {
		let url = `${proxy}/product/${productId}/tasks/${taskId}`;
		const req = await axios.delete(url);
		return req.data;
	};

	public postProductTask = async (productId: number, productTask: ProductTaskDto): Promise<ProductTaskDto> => {
		let url = `${proxy}/product/${productId}/tasks`;
		const req = await axios.post(url, productTask);
		return req.data;
	};
}

const ProductService = new ProductServices();
export default ProductService;
