import { Button, Col, Form, Row } from "react-bootstrap";
import ContentBox from "../../../components/ContentBox";
import { pageActions as actions } from "../../../store/page";
import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import Input from "../../../components/Form/Input";
import Loading from "../../../components/Loading";
import ProductService, { DocumentsByCategoryResponse, ProductsDocumentCategories } from "../../../services/productServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "../../../components/Form/Select";
import CheckBox from "../../../components/Form/CheckBox";

import type { Dispatch } from "redux";
import { AttachmentsResponse } from "../../../services/attachmentServices";
import AttachmentRow from "../../../components/AttachmentRow";
import { BasicModal } from "../../../components/BasicModal/Index";
import OpportunityService, { DocumentProduct, OpportunityDto } from "../../../services/opportunityServices";
import { AnnualInvestmentCharterDto } from "../../../services/annualInvestmentChartersServices";
import { PaddingRightIcon } from "@fluentui/react-icons-mdl2";
import AttachmentRowNoPadding from "../../../components/AttachmentRowNoPadding";
import CheckBoxNoPadding from "../../../components/Form/CheckBoxNoPadding";

type Props = {
    updatePageHeaderTitle: (title: string) => void;
};

const AssignmentSelectFilesProduct = (props: Props) =>
{
    const { productId } = useParams();
    const { updatePageHeaderTitle } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [documentsUrisByCategory, setDocumentsUrisByCategory] = useState<DocumentsByCategoryResponse[]>([]);
    const [fileCategories, setFileCategories] = useState<ProductsDocumentCategories[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>();
    const [selectedYear, setSelectedYear] = useState<number | undefined>(new Date().getFullYear());
    const [selectedFiles, setSelectedFiles] = useState<FormData>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [selectedDocumentToDelete, setSelectedDocumentToDelete] = useState<AttachmentsResponse | undefined>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { state } = useLocation();
    const capitalToRise: string | undefined = state?.capitalToRise;
    const opportunities: OpportunityDto[] = state.opportunities;
    const productype: string = state.productype;
    const charters: AnnualInvestmentCharterDto[] = state.charters;
    const inversionInfo: OpportunityDto[] = state.inversionInfo;
    const [selectedDocuments, setSelectedDocuments] = useState<{ [key: string]: boolean }>({});

    useEffect(() =>
    {
        updatePageHeaderTitle("Crear asignación. Paso 6: Seleccionar documentos adjuntos al contrato");
    }, [updatePageHeaderTitle]);

    useEffect(() =>
    {
        if (productId)
        {
            getProductAttachmentsHandler(Number(productId));
        }
    }, [productId]);

    useEffect(() =>
    {
        setIsLoading(true);
        ProductService.getProductDocumentCategories().then(response =>
        {
            setFileCategories(response);
            setIsLoading(false);
        });
    }, []);



    const docsChangeHandler = (event: any) =>
    {
        const formData = new FormData();
        let fileName = "";
        for (let i = 0; i < event.target.files.length; i++)
        {
            fileName = event.target.files[i]!["name"];
            formData.append(fileName, event.target.files[i]);
        }
        setSelectedFiles(formData);
    };

    const addProductAttachmentsHandler = () =>
    {
        if (selectedCategoryId && productId && !isNaN(Number(productId)))
        {
            setIsSubmitting(true);
            ProductService.addProductAttachments(Number(productId), selectedFiles!, selectedCategoryId, selectedYear)
                .then(attachmentsResponse =>
                {
                    getProductAttachmentsHandler(Number(productId));
                })
                .finally(() =>
                {
                    setIsSubmitting(false);
                });
        }
    };

    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleShowDeleteModal = () => setShowDeleteModal(true);

    const getProductAttachmentsHandler = (id: number) =>
    {
        setIsLoading(true);
        ProductService.getProductAttachments(id)
            .then(response =>
            {
                setDocumentsUrisByCategory(response);
                setIsLoading(false);
            })
            .catch(() => { });
    };

    const openBlob = async (productId: number, attachment: AttachmentsResponse) =>
    {
        const response = await ProductService.downloadDocument(productId, attachment.id);
        const file = new Blob([response.data], { type: response.headers["content-type"] });

        var downloadLink = document.createElement("a");
        downloadLink.download = attachment.fileName;
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleCheckboxChange = (id: number, checked: boolean) =>
    {
        setSelectedDocuments(prev => ({
            ...prev,
            [id]: checked
        }));
    };

    const associateDocumentsToOpportunities = () =>
    {
        const listOfIdsOfDocs = Object.entries(selectedDocuments)
            .filter(([id, checked]) => checked)
            .map(([id]) => Number(id));

        let opportunitiesIds: number[] = [];

        opportunities.map(e => opportunitiesIds.push(e.id!));

        var data: DocumentProduct = {
            idsDocuments: listOfIdsOfDocs,
            idsOpportunities: opportunitiesIds
        };

        OpportunityService.postDocumentProducts(data)
            .then(response =>
            {
                if (response == true)
                {
                    navigate("/assignments")
                }
            })
            .finally(() =>
            {
                setIsLoading(false);
            });


    }

    return (
        <ContentBox>
            <>
                {isLoading && <Loading type="component"></Loading>}
                {!isLoading && (
                    <>
                        <Row>
                            <p>Seleccione los documentos que desee adjuntar como anexos al contrato.</p>
                            <p>También puede adjuntar nuevos archivos que serán asociados al producto. Recuerde que debe <b>seleccionarlo después de cargarlo para adjuntarlo al contrato</b>.</p>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <Form.Group controlId="formFile">
                                    <Form.Label>Documentación</Form.Label>
                                    <Form.Control type="file" multiple onChange={docsChangeHandler} />
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Select
                                    name="productFileCategories"
                                    type="text"
                                    label="Categoria"
                                    value={selectedCategoryId}
                                    onChange={(e: any) =>
                                    {
                                        setSelectedCategoryId(e.target.value);
                                    }}
                                >
                                    {fileCategories &&
                                        fileCategories.map(category => (
                                            <option value={category.id} key={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                </Select>
                            </Col>
                            <Col lg={3}>
                                <Input
                                    name="fileYear"
                                    label="Año"
                                    value={selectedYear?.toString()}
                                    onChange={(e: any) =>
                                    {
                                        const inputValue = e.target.value;
                                        if (inputValue === "" || /^[1-9]\d*$/.test(inputValue))
                                        {
                                            setSelectedYear(inputValue);
                                        }
                                    }}
                                ></Input>
                            </Col>
                            <Col lg={3} style={{ marginTop: "auto" }}>
                                <Button
                                    disabled={isSubmitting || !selectedCategoryId || !selectedFiles || selectedCategoryId < 1}
                                    className="mr-10"
                                    onClick={() =>
                                    {
                                        setIsLoading(true);
                                        addProductAttachmentsHandler();
                                    }}
                                >
                                    Añadir
                                </Button>
                                <Button
                                    disabled={true}
                                    className="mr-10"
                                    onClick={() =>
                                    {
                                        // navigate(`/assignments/${productId}/email-fin`, {
                                        //     state: { opportunities, productype, charters, capitalToRise, inversionInfo }
                                        // });
                                    }}
                                >
                                    &#60; Anterior
                                </Button>
                                <Button
                                    disabled={isSubmitting}
                                    onClick={associateDocumentsToOpportunities}
                                >
                                    Siguiente &#62;
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            {documentsUrisByCategory && documentsUrisByCategory.length > 0 && (
                                <Col lg={10}>
                                    <table style={{ margin: "20px 0px 0px 10px" }}>
                                        <thead>
                                            <tr>
                                                <th>Archivos subidos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documentsUrisByCategory.map((category, catIndex) => (
                                                <Fragment key={catIndex}>
                                                    <tr>
                                                        <td style={{ padding: "5px 5px 5px 10px" }}>
                                                            <li>{category.name}</li>
                                                        </td>
                                                    </tr>
                                                    {category.productFiles &&
                                                        category.productFiles.map((doc: AttachmentsResponse, index: number) =>
                                                        {
                                                            return (
                                                                <div key={doc.id} style={{ display: "flex", alignItems: "center" }}>
                                                                    <CheckBoxNoPadding
                                                                        checked={selectedDocuments[doc.id] || false} // Usa el identificador único para saber si está seleccionado
                                                                        onChange={(e: any) =>
                                                                        {
                                                                            handleCheckboxChange(doc.id, e.target.checked);
                                                                        }}
                                                                        style={{ paddingRight: "0px", marginTop: "6px" }}
                                                                    />
                                                                    <AttachmentRowNoPadding
                                                                        doc={doc}
                                                                        onClick={(e, doc) =>
                                                                        {
                                                                            openBlob(Number(productId!), doc);
                                                                        }}
                                                                        onDeleteClick={(e, doc) =>
                                                                        {
                                                                            e.stopPropagation();
                                                                            setSelectedDocumentToDelete(doc);
                                                                            handleShowDeleteModal();
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                </Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </Col>
                            )}
                        </Row>
                    </>
                )}
                {/* Modal Eliminar documentación */}
                {selectedDocumentToDelete && (
                    <BasicModal
                        title={selectedDocumentToDelete!.fileName ?? ""}
                        body={
                            <>
                                El documento elegido se eliminará.
                                <br />
                                <br />
                                <b>¿Deseas continuar?</b>
                            </>
                        }
                        btnSubmitText="Eliminar"
                        showModal={showDeleteModal}
                        onSubmit={() =>
                        {
                            let ids: number[] = [selectedDocumentToDelete!.id ?? 0];
                            ProductService.deleteProductAttachments(Number(productId), ids)
                                .then(resp =>
                                {
                                    getProductAttachmentsHandler(Number(productId));
                                })
                                .catch(() => { });
                        }}
                        onClose={() =>
                        { //navigate("/assignments")
                            handleCloseDeleteModal();
                        }}
                    ></BasicModal>
                )}
            </>
        </ContentBox>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updatePageHeaderTitle: (title: string) => dispatch(actions.updatePageHeaderTitle(title))
});

export default connect(null, mapDispatchToProps)(AssignmentSelectFilesProduct);
