import React from "react";
import { pageActions as actions } from "../../store/page";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import ContentBox from "../../components/ContentBox";
import Loading from "../../components/Loading";
import { Button } from "react-bootstrap";
import { BackButton } from "../../components/BackButton";
import DataTable from "../../components/Table";
import IconButton from "../../components/IconButtonWithTooltip";
import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { ModalWithInputs } from "../../components/ModalWithInputs/Index";
import InvestorServices, { IbanTradeAccount } from "../../services/investorServices";
import { BasicModal } from "../../components/BasicModal/Index";
import { TextField } from "@mui/material";
import { ModalWithInputsVersionEmail } from "../../components/ModalWithInputsVersionEmail/Index";

type Props = {
    updatePageHeaderTitle: (title: string) => void;
};

const IbanTrade = (props: Props) =>
{
    const { updatePageHeaderTitle } = props;
    const [loading, setLoading] = React.useState(false);

    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [error, setError] = React.useState("");
    const [errorModal, setErrorModal] = React.useState<boolean>(false);
    const [correctResponseValidate, setCorrectResponseValidate] = React.useState(false);

    const [createBankAccountModal, setCreateBankAccountModal] = React.useState<boolean>(false);
    const [bankAccountTrade, setBankAccountTrade] = React.useState<IbanTradeAccount[]>([]);

    React.useEffect(() =>
    {
        updatePageHeaderTitle("Iban Compra Venta");
    }, [updatePageHeaderTitle]);

    React.useEffect(() =>
    {
        getIbanTradeData();
    }, []);

    React.useEffect(() =>
    {
        getIbanTradeData();

    }, [refresh]);


    const getIbanTradeData = () =>
    {
        setLoading(true);

        InvestorServices.getIbansTrade()
            .then(response =>
            {
                setBankAccountTrade(response);
            })
            .finally(() =>
            {
                setLoading(false);
            }).catch(() =>
            {
                setErrorModal(false);
                setError("Ha ocurrido un error al intentar obtener las cuentas bancarias");
            });
    }

    const createIbanTradeData = async (bankAccountFirst: string, destinatary: string, concept: string) =>
    {
        setLoading(true);

        var ibanTrade: IbanTradeAccount = {
            id: 0,
            iban: bankAccountFirst,
            isDefault: true,
            destinatary: destinatary,
            concept: concept
        };


        InvestorServices.postIbansTrade(ibanTrade)
            .then((response: IbanTradeAccount) =>
            {
                if (typeof response == "number")
                {

                    setCorrectResponseValidate(true);
                    setRefresh((prevState: boolean) => !prevState);
                    setLoading(false);
                }
                else
                {
                    setLoading(false);
                    setCorrectResponseValidate(true);
                    setCreateBankAccountModal(false)
                    setErrorModal(true);
                    setError("Ha ocurrido un error al intentar añadir una nueva cuenta bancaria. Recuerde que no se puede introducir destinatarios con el mismo nombre.");
                }

            })
            .catch(() =>
            {
                setLoading(false);
                setErrorModal(true);
                setCreateBankAccountModal(false)
                setError("Ha ocurrido un error al intentar añadir una nueva cuenta bancaria. Recuerde que no se puede introducir destinatarios con el mismo nombre.");
            });

    }

    const deleteBankAccount = async (bankAccountId: number) =>
    {

        setLoading(true);

        InvestorServices.deleteIbansTrade(bankAccountId).then(response =>
        {
            if (response == true)
            {
                setRefresh((prevState: boolean) => !prevState);
            }
        }).catch(() =>
        {
            setLoading(false);
            setErrorModal(true);
            setError("Ha ocurrido un error al intentar borrar la cuenta de usuario");
        });

    }

    return (
        <>
            <BackButton />
            {loading && <Loading type="component"></Loading>}
            {!loading && <ContentBox headLabel={"Cuentas Bancarias Compraventa"}>
                <>
                    <div style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                            variant="primary"
                            onClick={() => setCreateBankAccountModal(true)}
                            style={{ marginBottom: "10px" }}
                        >
                            Añadir Cuenta Bancaria
                        </Button>
                    </div>
                    <DataTable
                        id="ibanTradeTable"
                        size="lg"
                        isLoading={loading}
                        totalRows={bankAccountTrade.length}
                        selectedRowsPerPage={20}
                        head={
                            <tr key="headerkey">
                                <th>Destinatario</th>
                                <th>Concepto</th>
                                <th>IBAN</th>
                                <th></th>
                            </tr>
                        }
                        body={
                            bankAccountTrade.map((bankAccount) => (
                                <tr key={bankAccount.id} style={{ cursor: "pointer" }}>
                                    <td>{bankAccount.destinatary}</td>
                                    <td>{bankAccount.concept}</td>
                                    <td>{bankAccount.iban}</td>
                                    <td>
                                        <IconButton
                                            id={bankAccount.id}
                                            tooltipPlacement="top"
                                            tooltip="Eliminar Cuenta Bancaria"
                                            icon={<DeleteIcon className="AppIcon" />}
                                            onClick={(e: any) =>
                                            {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                deleteBankAccount(bankAccount.id);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                    /></>
                <ModalWithInputsVersionEmail
                    title="Añadir nueva cuenta bancaria"
                    showModal={createBankAccountModal}
                    showValidateModal={setCreateBankAccountModal}
                    onClose={() => setCreateBankAccountModal(false)}
                    size="lg"
                    onSubmit={(bankAccount: string, name: string, email: string) => createIbanTradeData(bankAccount, name, email)}
                    sendPetition={(bankAccount: string, name: string, email: string) => createIbanTradeData(bankAccount, name, email)}
                    body={
                        <></>
                    }
                    correctResponse={correctResponseValidate}
                />
                <BasicModal
                    title="Error"
                    body={<>{error}</>}
                    btnSubmitText="Aceptar"
                    showModal={errorModal}
                    onSubmit={() =>
                    {                  
                        setLoading(false);
                        setErrorModal(false);
                    }}
                    onClose={() =>
                    {
                        setLoading(false);
                        setErrorModal(false);
                    }}
                ></BasicModal>
            </ContentBox>}
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updatePageHeaderTitle: (title: string) => dispatch(actions.updatePageHeaderTitle(title))
});

export default connect(null, mapDispatchToProps)(IbanTrade);
